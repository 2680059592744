import { createGlobalStyle } from "styled-components";
import { styles } from "assets/templateCSS";
import LogoMain from "assets/logo_main.png";
import { CSS_VARIABLES } from "enums/cssVariables";

export const buildGlobalStylesComponent = ({
    overrides,
    notistackOverrides,
}: {
    overrides?: string | null;
    notistackOverrides?: string | null;
}) => createGlobalStyle`

/* 
    By default, #root spans the entire width and height of the viewport, 
    which can block interactions with other elements in the DOM, such as our zoom component or voting elements. 
    To prevent #root from interfering, we set "pointer-events: none;" on #root, disabling pointer events for it. 
    Then, we selectively re-enable pointer events for its child elements with "pointer-events: auto;", 
    allowing interaction with those specific components while still avoiding conflicts caused by the #root element.
*/

/* 
    The #root element has been assigned a z-index: 1 to address an integration issue with Zoom, 
    which required this element to stay in front of certain other interface elements. 
    However, this creates a conflict when a modal is opened, as the modal can't properly appear 
    above the #root element.

    To work around this, when the body has the class ReactModal__Body--open, 
    we reset all z-index values to "auto" using body.ReactModal__Body--open *.
    This ensures that the modal is visible on top of everything else.
*/
#root {
    z-index: 1;
    pointer-events: none;
}


#root > * {
    pointer-events: auto;
}


/* 
  We apply this adjustment because, by default, the camera popup 
  overlaps our voting design element which is located in the same position.
*/

#zmmtg-root #mobile-suspension-window-wrapper {
    inset: 70% 4px auto auto !important;
}

/* 
  We apply this adjustment because, by default, the camera popup 
  overlaps our voting design element which is located in the same position.
*/
[aria-label="suspension-window"] {
    inset: 70% 4px auto auto !important;
} 

body {
    display: flex;
    flex-direction: column;
    margin: 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

*{
    ${styles}
    ${CSS_VARIABLES.PRIMARY_BUTTON_TEXT_COLOR}: #ffffff;
    ${CSS_VARIABLES.PRIMARY_BUTTON_BORDER_COLOR}: #567183;
    ${CSS_VARIABLES.BACKGROUND_FORM_COLOR}: #fafafa;
    ${CSS_VARIABLES.LOGO_ALIGNMENT}: center;
    ${CSS_VARIABLES.LOGO_URL}: url(${LogoMain});
    ${CSS_VARIABLES.INSTALLATION_BUTTON_DISPLAY}: none;
    ${overrides || ""}
    ${notistackOverrides || ""}
    ${CSS_VARIABLES.BOX_SHADOW_PIXEL}: ${
    overrides?.includes(CSS_VARIABLES.BACKGROUND_FORM_COLOR)
        ? "0px 0px 0px 2px;"
        : "0px 0px 3px 0px;"
}
    ${CSS_VARIABLES.BORDER_FORM_COLOR}: ${
    overrides?.includes(`${CSS_VARIABLES.BACKGROUND_FORM_COLOR}`)
        ? `var(${CSS_VARIABLES.BACKGROUND_FORM_COLOR});`
        : "rgb(0 0 0 / 35%);"
}
    ${CSS_VARIABLES.LOGO_MAX_HEIGHT}: ${
    overrides?.includes(`${CSS_VARIABLES.BACKGROUND_FORM_COLOR}`)
        ? "120px"
        : "57px"
}
}

`;
